import { Injectable } from "@angular/core";
import * as XLSX from "xlsx";
import { jsPDF, TableConfig } from "jspdf";
import 'jspdf-autotable';

@Injectable()
export class ExcelService {
    constructor() { }

    static toExportFileName(excelFileName: string): string {
        return `${excelFileName}_export_${new Date().getTime()}.xlsx`;
    }

    public exportAsExcel(json: any[], excelFileName: string): void {
        var wscols = [];
        var value = undefined;
        let objectMaxLength: any = {};
        for (let i = 0; i < json.length; i++) {
            value = <any>Object.keys(json[i]);
            for (let j = 0; j < value.length; j++) {
                if (typeof value[j] === "number") {
                    objectMaxLength[j] = 10;
                } else {
                    objectMaxLength[j] =
                        objectMaxLength[j] >= value[j].length
                            ? objectMaxLength[j]
                            : value[j].length;
                    wscols.push({ wch: objectMaxLength[j] + 5 });
                }
            }
        }
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, {
            header: value,
            cellStyles: true,
        });

        if (wscols && wscols.length > 0) worksheet["!cols"] = wscols;

        const workbook: XLSX.WorkBook = {
            Sheets: { data: worksheet },
            SheetNames: ["data"],
        };

        let caption = ExcelService.toExportFileName(excelFileName);

        XLSX.writeFile(workbook, caption, {
            bookType: "xlsx",
            type: "buffer",
            cellStyles: true,
        });

    }

    public exportAsExcelFile(json: any[], excelFileName: string, columns: any[]): void {
        var wscols = [];
        var arr = [];
        var value = undefined;
        var header = [];
        if (json.length > 0) {
            json.forEach(element => {
                let obj = {}
                if (element) {
                    Object.keys(element).forEach(c => {
                        if (columns.filter(h => h.prop === c).length > 0) {
                            obj[c] = element[c];
                        }
                    })
                    arr.push(obj);
                }
            });

        }
        let array: any[] = [];
        for (let ind = 0; ind < arr.length; ind++) {
            const element = arr[ind];

            let obj = {};
            columns.forEach(ele => {

                var rule = /\s{1,}/g;
                let str = ele.name
                str = str.split(rule).join("_");

                obj[str] = element[ele.prop];

            })

            array.push(obj)
        }

        json = [...array];

        let objectMaxLength: any = {};
        for (let i = 0; i < json.length; i++) {
            value = <any>Object.keys(json[i]);
            for (let j = 0; j < value.length; j++) {
                if (typeof value[j] === "number") {
                    objectMaxLength[j] = 10;
                } else {
                    objectMaxLength[j] =
                        objectMaxLength[j] >= value[j].length
                            ? objectMaxLength[j]
                            : value[j].length;
                    wscols.push({ wch: objectMaxLength[j] + 5 });
                }
            }
        }

        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, {
            header: value,
            cellStyles: true,
        });

        if (wscols && wscols.length > 0) {

            worksheet["!cols"] = wscols;
        }

        const workbook: XLSX.WorkBook = {
            Sheets: { data: worksheet },
            SheetNames: ["data"],
        };

        let caption = ExcelService.toExportFileName(excelFileName);

        XLSX.writeFile(workbook, caption, {
            bookType: "xlsx",
            type: "buffer",
            cellStyles: true,
        });

    }

    async exceller(key) {
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(key);

        const wb: XLSX.WorkBook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'ScoreSheet.xlsx');
    }

    exportTableToCSV(filename) {
        var csv = [];
        var rows: any = document.querySelectorAll("datatable-row-wrapper");
        var header: any = document.querySelectorAll("datatable-header-cell");
        let head: any[] = [];
        for (let index = 0; index < header.length; index++) {
            head.push(header[index].innerText)
        }
        csv.push(head.join(","));
        for (var i = 0; i < rows.length; i++) {
            var grp = [], group = rows[i].querySelectorAll(".datatable-group-header")[0];
            var row = [], cols = rows[i].querySelectorAll("datatable-body-row");
            if (group) {
                grp.push(group.innerText);
                csv.push(grp.join(","));
            }

            if (cols && cols.length > 0) {

                for (var j = 0; j < cols.length; j++) {

                    var column = [], columns = cols[j].querySelectorAll("datatable-body-cell");

                    for (var f = 0; f < columns.length; f++) {
                        column.push(columns[f].innerText);
                    }

                    csv.push(column.join(","));
                }
            }
            else {
                var column = [], columns = cols.querySelectorAll("datatable-body-cell");
                for (var j = 0; j < columns.length; j++) {
                    column.push(columns[j].innerText);
                }
                csv.push(column.join(","));
            }

        }
        // Download CSV file
        this.downloadCSV(csv.join("\n"), filename);
        // var csv = [];
        // var rows: any = document.querySelectorAll("table tr");

        // for (var i = 0; i < rows.length; i++) {
        //     var row = [], cols = rows[i].querySelectorAll("td, th");

        //     for (var j = 0; j < cols.length; j++)
        //         row.push(cols[j].innerText);

        //     csv.push(row.join(","));
        // }

        // // Download CSV file
        // this.downloadCSV(csv.join("\n"), filename);
    }
    exportListingTableToCSV(filename: any, type: number = 1) {
        let section = document.getElementById('content2');
        let head: any[] = [];
        let headdata: any[] = [];
        let headdataObj: any[] = [];
        let rowsdata: any[] = [];
        if (section) {
            var csv = [];

            var rows: any = section.querySelectorAll("datatable-row-wrapper");
            var header: any = section.querySelectorAll("datatable-header-cell");
            for (let index = 0; index < header.length; index++) {
                head.push(header[index].innerText)
                if (header[index].innerText !== "Action" && headdata.length === 0)
                    headdata.push(head)
            }
            csv.push(head.join(","));
            headdataObj.push(headdata)
            for (var i = 0; i < rows.length; i++) {
                var grp = [], group = rows[i].querySelectorAll(".datatable-group-header")[0];
                var row = [], cols = rows[i].querySelectorAll("datatable-body-row");
                if (group) {
                    grp.push(group.innerText);
                    csv.push(grp.join(","));
                }

                if (cols && cols.length > 0) {

                    for (var j = 0; j < cols.length; j++) {

                        var column = [], columns = cols[j].querySelectorAll("datatable-body-cell");

                        for (var f = 0; f < columns.length; f++) {
                            column.push(columns[f].innerText);
                            rowsdata.push(column)
                        }

                        csv.push(column.join(","));
                    }
                }
                else {
                    var column = [], columns = cols.querySelectorAll("datatable-body-cell");
                    for (var j = 0; j < columns.length; j++) {
                        column.push(columns[j].innerText);
                        rowsdata.push(column)
                    }
                    csv.push(column.join(","));
                }
            }

        }
        let heading: any = document.getElementsByClassName('appHeading');
        if (heading !== null && heading.length > 0) {
            let name = heading[0].innerText ? heading[0].innerText : '';
            if (name)
                filename = name
        }
        
        // Download CSV file
        if (type === 1)
            this.downloadCSV(csv.join("\n"), filename);
        if (type === 2)
            this.downloadPDF(filename, headdataObj, rowsdata);
        // var csv = [];
        // var rows: any = document.querySelectorAll("table tr");

        // for (var i = 0; i < rows.length; i++) {
        //     var row = [], cols = rows[i].querySelectorAll("td, th");

        //     for (var j = 0; j < cols.length; j++)
        //         row.push(cols[j].innerText);

        //     csv.push(row.join(","));
        // }

        // // Download CSV file
        // this.downloadCSV(csv.join("\n"), filename);
    }

    async downloadCSV(csv, filename) {
        var csvFile;
        var downloadLink;

        // CSV file
        csvFile = new Blob([csv], { type: "text/csv" });

        // Download link
        downloadLink = document.createElement("a");

        // File name
        downloadLink.download = filename;

        // Create a link to the file
        downloadLink.href = window.URL.createObjectURL(csvFile);

        // Hide download link
        downloadLink.style.display = "none";

        // Add the link to DOM
        document.body.appendChild(downloadLink);

        // Click download link
        downloadLink.click();
    }
    async downloadPDF(filename, header, row) {
        var doc = new jsPDF();
        
        doc.setFontSize(18);
        // doc.text('My Team Detail', 11, 8);
        doc.setFontSize(11);
        doc.setTextColor(100);
        
        (doc as any).autoTable({
            head: header,
            body: row,
            theme: 'plain',
            didDrawCell: data => {
                console.log(data.column.index)
            }
        })
        // below line for Open PDF document in new tab
        doc.output('dataurlnewwindow')

        // below line for Download PDF document  
        doc.save(filename);
    }


}
