<label style="display: flex">
    <div class="select-editable" style="width: 100%;">
        <select class="listerselect listerselected" (keyup.backspace)="ondelete($event)"
            (change)="changeValue($event.target.value)" (keyup.delete)="ondelete($event)"
            [ngStyle]="{ 'width': _width }" style="border: none;" [disabled]="disabled" [(ngModel)]="value"
            (ngModelChange)="Modelchange()" #select="ngModel">
            <option *ngFor="let item of data" [value]="item[valueMember]">{{item[displayMember]}}</option>
        </select>
        <input *ngIf="_isDisabled" type="text" name="format" [value]="displayVal" disabled
            (keyup)="keyup($event.target.value)" (keydown.enter)="onEnter($event.target.value, $event.keyCode)" style="background: transparent;margin-top: -2px;width: 100%;" />
        <input *ngIf="!_isDisabled" [type]="inputType" name="format" [value]="displayVal" [disabled]="_isDisabled"
            (keyup)="keyup($event.target.value)" (keydown.enter)="onEnter($event.target.value, $event.keyCode)"
            style="background: transparent;margin-top: -2px;width: 100%;" />
    </div>
    <!-- <span style="padding-left: 4px; cursor: pointer;" (click)="openlister()"><i
            class="simple-icon-magnifier"></i></span> -->

    <span style="padding-left: 4px; cursor: pointer;" (click)="openAccLister()"><i
            class="simple-icon-magnifier"></i></span>
    <span *ngIf="isButton" style="padding-left: 4px; cursor: pointer;" (click)="openModal()"><i
            class="iconsminds-add"></i></span>
</label>

<label style="display: flex" *ngIf="design === 2">
    <div class="select-editable" style="width: 100%;">
        <select class="listerselect listerselected" (keyup.backspace)="ondelete($event)"
            (change)="changeValue($event.target.value)" (keyup.delete)="ondelete($event)"
            [ngStyle]="{ 'width': _width }" style="border: none;" [disabled]="disabled" [(ngModel)]="value"
            (ngModelChange)="Modelchange()" #select="ngModel">
            <option *ngFor="let item of data" [value]="item[valueMember]">{{item[displayMember]}}</option>
        </select>
        <input [type]="type" name="format" [value]="displayVal" [disabled]="isDisabled"
            (keydown.enter)="onEnter($event.target.value, $event.keyCode)"
            style="background: transparent;margin-top: -2px;width: 100%;" />
    </div>
    <span style="padding-left: 4px; cursor: pointer;" (click)="openAccLister()"><i
            class="simple-icon-magnifier"></i></span>
    <span *ngIf="isButton" style="padding-left: 4px; cursor: pointer;" (click)="openModal()"><i
            class="iconsminds-add"></i></span>
</label>

<template #inputlookup></template>
<simple-notifications></simple-notifications>
<ng-template #accessoryModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Accessories</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="accessoryModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="min-height: 230px;">
        <!-- <form [formGroup]="accessoryInformation"> -->
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-6">
                        <biz-dropdown caption="Store" [design]="2" [data]="store"
                            [(ngModel)]="accessoryInformation.fkStoreTypeID"
                            (change)="FillAccHead(1,accessoryInformation.fkStoreTypeID)" [valueMember]="'id'"
                            [displayMember]="'name'">
                        </biz-dropdown>
                    </div>
                    <div class="col-lg-6">
                        <biz-dropdown caption="Accessory Head" [design]="2" [data]="head"
                            [(ngModel)]="accessoryInformation.fkHeadID"
                            (change)="changeHead(accessoryInformation.fkHeadID)" [valueMember]="'id'"
                            [displayMember]="'name'">
                        </biz-dropdown>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <label class="form-group has-float-label">
                            <input class="form-control" list="headEx" [(ngModel)]="accessoryInformation.fkHeadExtName">
                            <datalist id="headEx">
                                <option *ngFor="let i of AccessoriesHead" [value]="i.name"> {{i.name}}
                            </datalist>
                            <span class="spanCaption">Head Ext.</span>
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <biz-select caption="Color" [data]="" [design]="2"
                            [(ngModel)]="accessoryInformation.fkAccColorID" apiController="SalesAccColor"
                            apiRoute="getSearchby" apiModule="Accessories"  [valueMember]="'id'"
                            [apiParameters]="brandParams" [displayMember]="'name'">
                        </biz-select>
                    </div>
                    <div class="col-6">
                        <biz-select caption="Size" [data]="" [design]="2" [(ngModel)]="accessoryInformation.fkAccSizeID"
                            apiController="SalesAccSize" apiRoute="getSearchby" apiModule="Accessories"
                            [valueMember]="'id'" [apiParameters]="brandParams" [displayMember]="'name'">
                        </biz-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <biz-select caption="Brand" [data]="" [design]="2"
                            [(ngModel)]="accessoryInformation.fkAccBrandIDNew" apiController="SalesAccBrandNew"
                            apiRoute="getSearchby" apiModule="Accessories" [valueMember]="'id'"
                            [apiParameters]="brandParams" [displayMember]="'name'">
                        </biz-select>
                    </div>
                    <div class="col-6">
                        <biz-select caption="Unit" [data]="" [design]="2" [(ngModel)]="accessoryInformation.unit"
                            apiController="AccUnits" apiRoute="getSearchby" apiModule="Accessories" [valueMember]="'id'"
                            [apiParameters]="brandParams" [displayMember]="'name'">
                        </biz-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <biz-select caption="Slider Type" [data]="" [design]="2"
                            [(ngModel)]="accessoryInformation.fkWidth" apiController="AccWidth" apiRoute="getSearchby"
                            apiModule="Accessories" [valueMember]="'id'" [apiParameters]="brandParams"
                            [displayMember]="'name'">
                        </biz-select>
                    </div>
                    <div class="col-6">
                        <biz-select caption="Finish Type" [data]="" [design]="2"
                            [(ngModel)]="accessoryInformation.fkOwns" apiController="AccOwns" apiRoute="getSearchby"
                            apiModule="Accessories" [valueMember]="'id'" [apiParameters]="brandParams"
                            [displayMember]="'name'">
                        </biz-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <biz-select caption="Customer" [data]="" [design]="2"
                            [(ngModel)]="accessoryInformation.fkAccBrandID" apiController="SalesCustomer"
                            apiRoute="getSearchby" apiModule="apiURL" [valueMember]="'id'"
                            [apiParameters]="customerParams" [displayMember]="'cName'">
                        </biz-select>
                    </div>
                </div>
            </div>
        </div>
        <!-- </form> -->
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary float-right" (click)="selectAccessory()">Select</button>
    </div>
</ng-template>

<style>
    .listerDropdown {
        display: flex;

    }

    .listerselect {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
    }

    .listerselected option {
        display: none;
    }

    .select-editable {
        position: relative;
        width: 120px;
        height: 18px;
    }

    .select-editable select {
        position: absolute;
        top: 0px;
        left: 0px;
        font-size: 14px;
        border: none;
        width: 120px;
        margin: 0;
    }

    .select-editable input {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100px;
        padding: 1px;
        font-size: 12px;
        border: none;
    }

    .select-editable select:focus,
    .select-editable input:focus {
        outline: none;
    }
</style>